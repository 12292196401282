<template>
<div class="my-2 py-2">
      <b-row class="sticky-top bg-white games-header d-none d-md-flex" :class="scrolled ? 'sticky-top-shadow' : ''">
            <b-col cols="2" >Match</b-col>
            <b-col cols="2" >Tid</b-col>
            <b-col cols="3" >Hemma</b-col>
            <b-col cols="2" class="text-center">Resultat</b-col>
            <b-col cols="3" class="text-right">Borta</b-col>
      </b-row>
      <div v-for="(ggames, index) in games" :key="index" class="mb-5">
            <b-row class="font-weight-bold mt-2 border-bottom font-large" no-gutters>
                  <b-col cols="12">
                        {{ggames[0].group.name}}
                  </b-col>
            </b-row>
            <GroupResultRow v-for="(game, index) in ggames" :key="index" :game="game"></GroupResultRow>
      </div>
</div>
</template>

<script>
const GroupResultRow = () => import("@/components/games/GroupResultRow");

export default {
      name: "GroupGames",
      props: ["games"],
      components: {
            GroupResultRow
      },
      data() {
            return {
                  scrolled: false
            }
      },
      computed: {},
      methods: {
            date(date) {
                  return this.$date.stringToReadableFormat(date);
            },
            handleScroll() {
                  this.scrolled = window.scrollY > 0;
            }
      },
      created() {
            window.addEventListener('scroll', this.handleScroll);
      },
      destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
      }
};
</script>
